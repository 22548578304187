import { TIMEZONE_IANAS } from '@/constants/timezone-ianas.js'
import { logError } from '@/utils/error-handling.js'

const convertToDate = function (timestamp, timezone) {
	if (!TIMEZONE_IANAS.includes(timezone)) {
		timezone = 'America/New_York'
	}
	try {
		const jsTime = timestamp * 1000
		const [ month, date, year ] = new Date(jsTime).toLocaleDateString('en-US', { timeZone: timezone }).split('/')
		const [ hour, minute, second, meridiem ] = new Date(jsTime).toLocaleTimeString('en-US', { timeZone: timezone }).split(/:| /)
		const timezoneShort = new Date(jsTime)
			.toLocaleDateString('en-us', {
				timeZoneName: 'short',
				timeZone: timezone
			}).split(',')[1]

		return { month, date, year, hour, minute, second, meridiem, timezoneShort }
	} catch (e) {
		logError(e)
	}
}

const formatUnixTime = function (timestamp, timezone) {
	if (parseInt(timestamp) < 1) return '-'

	const { hour, minute, meridiem, timezoneShort } = convertToDate(timestamp, timezone)

	// Will display time in 10:30:23 format
	const formattedTime = hour + ':' + minute + ' ' + meridiem

	return formattedTime + timezoneShort
}

const formatUnixDateTime = function (timestamp) {
	if (parseInt(timestamp) < 1) return '-'
	const { month, date, year, hour, minute, meridiem, timezoneShort } = convertToDate(timestamp)

	// Will display time in 10:30:23 format
	const formattedTime = month + '/' + date + '/' + year + ' - ' + hour + ':' + minute + meridiem + timezoneShort

	return formattedTime
}

const formatUnixDate = function (timestamp, forDefaultInput = false) {
	if (typeof Number(timestamp) !== 'number') return '-'
	const { month, date, year } = convertToDate(timestamp)

	// Will display time in 10:30:23 format
	try {
		let formattedTime = month + '/' + date + '/' + year?.substr(-2)
		if (forDefaultInput) {
			formattedTime = year + '-' + month + '-' + date
		}

		return formattedTime
	} catch (e) {
		logError(e)
		return ''
	}
}

export { formatUnixTime, formatUnixDateTime, formatUnixDate, convertToDate }
