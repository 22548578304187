export const ADDRESS = 'Please enter a valid address'
export const ZIP_CODE = 'Please enter a valid zip code'
export const CITY = 'Please enter a city'
export const FIRST_NAME = 'Please provide a first name'
export const LAST_NAME = 'Please provide a last name'
export const PHONE = 'Please enter a valid phone number'
export const DOB = 'Please enter a valid date of birth'
export const MED_ID = 'Please enter a medical ID number'
export const MED_ID_EXP = 'Please enter a valid medical card expiration date'
export const PERSONAL_ID = 'Please enter your State ID / DL number'
export const PERSONAL_ID_EXP = 'Please enter a valid ID expiration date'

export default {
	ADDRESS, ZIP_CODE, CITY, FIRST_NAME, LAST_NAME, PHONE, DOB, MED_ID, MED_ID_EXP, PERSONAL_ID, PERSONAL_ID_EXP
}
