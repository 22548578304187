export const LISTING_IMAGES = 'listing_images'
export const MENU_IMAGES = 'menu_images'
export const LOCATION_IMAGES = 'location_images'
export const DEAL_IMAGES = 'deal_images'
export const USER_IMAGES = 'user_images'
export const GUIDE_IMAGES = 'guide_images'
export const BLOG_IMAGES = 'blog_images'
export const CHAIN_IMAGES = 'chain_images'

export default { LISTING_IMAGES, MENU_IMAGES, LOCATION_IMAGES, DEAL_IMAGES, USER_IMAGES, GUIDE_IMAGES, BLOG_IMAGES, CHAIN_IMAGES }
